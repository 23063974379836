<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        Which of the following are parts of the design of laboratory ventilation? (More than 1
        answer is possible.)
      </p>

      <p class="mb-2" v-for="question in questions" :key="question.inputName">
        <v-checkbox v-model="inputs.studentAnswers" :value="question.value" class="pl-0 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="question.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'ChemUCI1LC_Q4S2_Q13',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      questions: [
        {
          text: 'Hallway air flows into the lab',
          value: 'a',
        },
        {
          text: 'Lab air leaves the lab through the fume hoods',
          value: 'b',
        },
        {
          text: 'The atmospheric pressure in the lab is greater than the hallway',
          value: 'c',
        },
        {
          text: 'Air exchanges should be at least 24 in 24 hours',
          value: 'd',
        },
        {
          text: 'Air enters the labs through heating/cooling ducts and hallway air',
          value: 'e',
        },
      ],
    };
  },
};
</script>
